.page {
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.wrapper {
  position: relative;
}

.loadingContainer {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}